import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthLogoutAction } from '../store/user/user.actions';

@Injectable()
export class AuthExpiredInterceptor implements HttpInterceptor {
  constructor(private store: Store) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap({
        next: (err: any) => {
          if (err.body) {
            if (err.body.status === 401) {
              this.store.dispatch(AuthLogoutAction({ goToLogin: true, number: '15', noLogout: true }));
            }
          }
        }
      })
    );
  }
}
