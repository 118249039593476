import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { ModalComponent } from './modal.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [ModalComponent],
  imports: [CommonModule, MatButtonModule, RouterModule, MatDialogModule, MatIconModule, ClipboardModule],
  exports: [ModalComponent]
})
export class ModalModule {}
