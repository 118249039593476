import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'iren-pa-spinner',
  templateUrl: './iren-pa-spinner.component.html',
  styleUrls: ['./iren-pa-spinner.component.scss']
})
export class IrenPaSpinnerComponent implements OnInit {
  @Input() loading: Observable<boolean>;
  constructor() {}

  ngOnInit(): void {}
}
