<button mat-button color="accent" class="go-back" (click)="goToLogin()">
  <mat-icon color="accent">arrow_back</mat-icon>
  <span>Torna alla login</span>
</button>
<div class="otp">
  <div *ngIf="data.google_qr_code && data.google_qr_code !== 'EMAIL'; else email">
    <h2 class="mb-2">Scansione il QR code per verificare il tuo account{{ (timer$ | async) ? ', hai\ ' + (timer$ | async) + '\ secondi' : '' }}</h2>
    <qr-code [value]="data.google_qr_code" size="256" errorCorrectionLevel="M"></qr-code>
    <form class="otp__form" [formGroup]="otpForm">
      <h2>Inserire il codice di Google Authenticator</h2>
      <div class="otp-input-container">
        <ng-otp-input [formCtrl]="controlOtp" [config]="{ length: 6, allowNumbersOnly: noReset }"></ng-otp-input>
      </div>
    </form>
    <button mat-raised-button color="accent" class="mt-1" (click)="sendOtp()" *ngIf="timer$ | async; else exitQR">Invia OTP</button>
    <ng-template #exitQR>
      <button mat-raised-button color="accent" class="mt-1" (click)="goToLogin()">Torna alla login</button>
    </ng-template>
  </div>
  <ng-template #email>
    <h2>È stato inviato il codice OTP {{ !data.firstAccess ? 'alla mail utilizzata' : 'al cellulare utilizzato' }} in fase di registrazione</h2>
    <form class="otp__form" [formGroup]="otpForm">
      <h2>Inserisci il codice OTP{{ (timer$ | async) ? ', hai\ ' + (timer$ | async) + '\ secondi' : '' }}</h2>
      <div class="otp-input-container">
        <ng-otp-input [formCtrl]="controlOtp" [config]="{ length: 6, allowNumbersOnly: noReset }"></ng-otp-input>
      </div>
    </form>
    <button mat-raised-button color="accent" (click)="sendOtp()" *ngIf="timer$ | async; else exit">Invia OTP</button>
    <h3>{{ !(timer$ | async) ? "Il tempo per l' inserimento dell' OTP è scaduto, devi effettuare di nuovo l' accesso" : '' }}</h3>
    <ng-template #exit>
      <button mat-raised-button color="accent" class="mt-1" (click)="goToLogin()">Torna alla login</button>
    </ng-template>
  </ng-template>
</div>
