import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OtpInputPageComponent } from './otp-input-page/otp-input-page.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { NgOtpInputModule } from 'ng-otp-input';
import { FirstAuthConfirmPageComponent } from './first-auth-confirm-page/first-auth-confirm-page.component';
import { OtpInputModalComponent } from './otp-input-modal/otp-input-modal.component';

import { SpinnerModule } from '@shared/spinner/spinner.module';
import { QrCodeModule } from 'ng-qrcode';

const routes = [
  { path: 'input', component: OtpInputPageComponent },
  { path: 'confirmed/:firstAccess', component: FirstAuthConfirmPageComponent }
];

@NgModule({
  declarations: [OtpInputPageComponent, FirstAuthConfirmPageComponent, OtpInputModalComponent],
  imports: [CommonModule, MatFormFieldModule, MatIconModule, SpinnerModule, ReactiveFormsModule, MatInputModule, MatButtonModule, NgOtpInputModule, MatDialogModule, QrCodeModule, RouterModule.forChild(routes)],
  exports: [OtpInputModalComponent]
})
export class OtpInputModule {}
