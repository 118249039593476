import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { UserService } from '@services/user.service';
import { differenceInHours } from 'date-fns';
import { Observable, filter, map, of, take, zip, tap, catchError } from 'rxjs';
import { AuthLogoutAction } from './store/user/user.actions';
import { AuthSelector, currentUser } from './store/user/user.selectors';
import { UtilityService } from '@services/utility.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  loading$: Observable<boolean> = of(true);
  loadingSession: boolean;

  constructor(private router: Router, private store: Store, private userService: UserService, private ref: ChangeDetectorRef, private utilityService: UtilityService) {}

  ngOnInit() {
    if (localStorage.getItem('paStoreKey') && JSON.parse(localStorage.getItem('paStoreKey') || '')?.auth?.expirationDate && differenceInHours(new Date(JSON.parse(localStorage.getItem('paStoreKey') || '')?.auth?.expirationDate), new Date()) >= 1) {
      this.store.dispatch(AuthLogoutAction({ goToLogin: true, number: '1' }));
    }

    this.userService.loadingSession$.subscribe({
      next: value => {
        this.loadingSession = value;
        console.log('Loading session status:', this.loadingSession);
        this.ref.detectChanges();
      }
    });

    zip(this.store.select(AuthSelector), this.store.select(currentUser))
      .pipe(take(1))
      .subscribe({
        next: res => {
          if (res[0]?.idToken && res[1]?.profile && !location.href.includes('my-area')) {
            if (this.userService.getUserProfile().profile?.type === 'BOE') this.router.navigate(['/my-area/homepage-boe']);
            else if (this.userService.getUserProfile().profile?.type === 'EDITOR') this.router.navigate(['/my-area/editor']);
            else if (this.userService.getUserProfile().profile?.type !== 'BOE' && this.userService.getUserProfile().profile?.type !== 'EDITOR') this.router.navigate(['/my-area']);
          } else if ((!res[0]?.idToken || !res[1]?.profile) && location.href.includes('my-area')) {
            this.router.navigate(['/login']);
          }
        }
      });

    // Gestione dello stato di caricamento in base agli eventi di navigazione
    this.loading$ = this.router.events.pipe(
      filter(e => e instanceof NavigationStart || e instanceof NavigationEnd || e instanceof NavigationCancel || e instanceof NavigationError),
      tap(e => console.log('Router event:', e)), // Log degli eventi di navigazione
      map(e => {
        const isLoading = e instanceof NavigationStart;
        const isNotFound = e instanceof NavigationEnd && e.url.includes('not-found'); // Supponendo che il tuo URL per la pagina not found contenga "not-found"
        //console.log('Is Loading:', isLoading, 'Is Not Found:', isNotFound); // Log delle condizioni
        return isLoading && !isNotFound;
      }),
      catchError(err => {
        //console.error('Error occurred:', err); // Log degli errori
        return of(false); // Torna un valore di default in caso di errore
      })
    );
  }

  ngOnDestroy() {
    this.userService.setUserProfile({});
    this.userService.destroyLoading();
  }
}
