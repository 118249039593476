import { Offer } from '@modules/catalog/catalog.interface';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IStoreState } from './store.state';

export const paStoreKey = 'paStoreKey';
export const storeSelector = createFeatureSelector<IStoreState>(paStoreKey);
export const storeProfileSelector = createSelector(storeSelector, state => state.profile);
export const MandatoryTrainingFlagSelector = createSelector(storeSelector, state => state.hasMandatoryTraining);
export const storeAgencySelector = createSelector(storeSelector, state => state.agencyList);
export const storeFireBaseSelector = createSelector(storeSelector, state => state.fineTutela);
export const storeFireBaseFlatSelector = createSelector(storeSelector, state => state.flat);
export const storeFlatOfferSelector = createSelector(storeSelector, state => state.flatOffer);
export const listaComuniSelector = createSelector(storeSelector, state => state.listaComuni);
// export const ProfileOfAgencySelector = createSelector(storeSelector, state => state.agencyList.filter((el=>el.canaliVenditaPartner)));
export const getAgencyByIdSelector = (id: number) => createSelector(storeSelector, state => state.agencyList.filter(el => el?.id === id));
export const getAgencyByMensSelector = (mens: string) => createSelector(storeSelector, state => state.agencyList.find(el => el?.codiceMens === mens));
export const getAgencyBySfidSelector = (sfid: string) => createSelector(storeSelector, state => state.agencyList.find(el => el?.sfid === sfid));

export const storeCatalogHomepageSelector = createSelector(storeSelector, state => {
  let commodityList = state.catalogCommodityHomeItem || [];
  let bundleList = state.catalogBundleHomeItem || [];
  let vasList = state.catalogVasHomeItem || [];
  let list: Partial<Offer>[] = [];
  if (commodityList.length > 0 && bundleList.length > 0 && vasList.length > 0) {
    list = [commodityList[0], bundleList[0], vasList[0]];
  } else if (commodityList.length < 1) {
    if (bundleList.length > 1 && vasList.length > 0) list = [bundleList[0], bundleList[1], vasList[0]];
    if (bundleList.length == 1 && vasList.length > 1) list = [bundleList[0], vasList[0], vasList[1]];
    if (bundleList.length < 1 && vasList.length > 2) list = [vasList[0], vasList[1], vasList[2]];
    if (bundleList.length > 2 && vasList.length < 1) list = [bundleList[0], bundleList[1], bundleList[2]];
  } else if (bundleList.length < 1) {
    if (commodityList.length > 1 && vasList.length > 0) list = [commodityList[0], commodityList[1], vasList[0]];
    if (commodityList.length == 1 && vasList.length > 1) list = [commodityList[0], vasList[0], vasList[1]];
    if (commodityList.length < 1 && vasList.length > 2) list = [vasList[0], vasList[1], vasList[2]];
    if (commodityList.length > 2 && vasList.length < 1) list = [commodityList[0], commodityList[1], commodityList[2]];
  } else if (vasList.length < 1) {
    if (commodityList.length > 1 && bundleList.length > 0) list = [commodityList[0], commodityList[1], bundleList[0]];
    if (commodityList.length == 1 && bundleList.length > 1) list = [commodityList[0], bundleList[0], bundleList[1]];
    if (commodityList.length < 1 && bundleList.length > 2) list = [bundleList[0], bundleList[1], bundleList[2]];
    if (commodityList.length > 2 && bundleList.length < 1) list = [commodityList[0], commodityList[1], commodityList[2]];
  }
  return list;
});
