import { Offer } from '@modules/catalog/catalog.interface';
import { AggregatedTracking, ILogin, Notifications, Profile } from '@modules/login/login.interface';
import { Training } from '@modules/training/store/training.state';
import { Agency } from '@shared/agency-pick/agency-pick/agency-pick.component';
import { paStoreKey } from './store.selector';
import { FlatOffer } from '@modules/flat-offers/flat-offers.interface';

export interface IStoreState {
  profile: Partial<Profile>;
  auth?: Partial<ILogin>;
  trainingList: Partial<Training>[] | null;
  trainingListHomepage: Partial<Training>[];
  notifications: Notifications[];
  dailyTask: Notifications[];
  agencyList: Agency[];
  fineTutela?: boolean;
  flat?: boolean;
  catalogCommodityHomeItem: Offer[];
  catalogBundleHomeItem: Offer[];
  catalogVasHomeItem: Offer[];
  aggregatedTracking: Partial<AggregatedTracking> | null;
  hasMandatoryTraining: boolean;
  flatOffer: Partial<FlatOffer>;
  listaComuni: Comune[];
}

export const EmptyStoreState: IStoreState = {
  profile: {},
  auth: {},
  trainingList: null,
  trainingListHomepage: [],
  notifications: [],
  dailyTask: [],
  agencyList: [],
  catalogCommodityHomeItem: [],
  catalogBundleHomeItem: [],
  catalogVasHomeItem: [],
  aggregatedTracking: null,
  hasMandatoryTraining: false,
  flatOffer: {},
  listaComuni: []
};

export interface Comune {
  value: string;
  viewValue: string;
  cessato: boolean;
}

const getStoreFromStorage = (): { paStoreKey: IStoreState } => {
  const state = localStorage.getItem(paStoreKey);
  return state ? { ...JSON.parse(state) } : { ...EmptyStoreState };
};

export const InitialStoreState: { paStoreKey: IStoreState } = getStoreFromStorage();
