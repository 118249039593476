<div class="exit-modal">
  <h1 mat-dialog-title>Sales {{ url.includes('pre-vendita') || url.includes('pre-check') || url.includes('esito') ? 'Precheck' : '' }}</h1>
  <div class="mt-2">
    <span class="copy">{{ copy }}</span>
  </div>
  <div class="exit-modal__actions">
    <ng-container *ngIf="!this.data?.copy; else selectLuminea">
      <button mat-raised-button [color]="this.data && !this.data.copy?.includes('Luminea') ? 'primary' : 'accent'" [mat-dialog-close]="true">Si</button>
      <button mat-raised-button [color]="this.data && !this.data.copy?.includes('Luminea') ? 'accent' : 'primary'" [mat-dialog-close]="false" cdkFocusInitial>No</button>
    </ng-container>
    <ng-container *ngIf="this.data?.copy && this.data?.copy === checkCfAndFornitureAddress">
      <button mat-raised-button (click)="installationAddressHandler(false)" [color]="'accent'">Chiudi</button>
    </ng-container>
    <ng-template #selectLuminea>
      <button *ngIf="this.data?.copy !== checkCfAndFornitureAddress" mat-raised-button color="accent" [mat-dialog-close]="true">Prosegui</button>
    </ng-template>
  </div>
</div>
