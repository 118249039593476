import { MatDialogModule } from '@angular/material/dialog';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { KickOutModalComponent } from './kickout-modal.component';

@NgModule({
  declarations: [KickOutModalComponent],
  imports: [CommonModule, MatButtonModule, MatDialogModule],
  exports: [KickOutModalComponent]
})
export class KickOutModalModule {}
