// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  name: 'stage',
  production: false,
  expiredSession: 52, //minuti
  expiredSessionModal: 3, //minuti
  PA_BASE_URL: 'https://iren-pa-web-uatd.herokuapp.com',
  PA_PUBLIC_BASE_URL: 'https://iren-pa-web-uatd.herokuapp.com/api/proxy',
  PA_PRIVATE_BASE_URL: 'https://iren-pa-web-uatd.herokuapp.com/api/proxy/private',
  PA_NDS_BASE_URL: 'https://iren-pa-web-uatd.herokuapp.com/api/proxy/nds',
  PA_MASSIVE_URL: 'https://iren-pa-massive-web-uatd.herokuapp.com',
  recaptcha: {
    siteKey: '6LdhzX0kAAAAACOQHUowuLCAPr9P_jyZqmSFMy7_'
  },
  firebase: {
    apiKey: 'AIzaSyBxlPrwFnotDa5qYUQdoAORBebrHffXLJY',
    authDomain: 'portale-agenti-dev.firebaseapp.com',
    projectId: 'portale-agenti-dev',
    storageBucket: 'portale-agenti-dev.appspot.com',
    messagingSenderId: '116165744289',
    appId: '1:116165744289:web:2b23bd8a13cccef63e42e0',
    measurementId: 'G-Y0185ZQDZV'
  },
  cloudinaryNameSpace: 'hqzwjp691',
  cloudinaryApiKey: '639269219221394',
  cloudinaryUploadPreset: 's1sqggrt',
  sentryDsn: 'https://f6f821dd69c4455297b40ceabe726d93@o4504809133768704.ingest.sentry.io/4505447993311232',
  linkOPNet: 'https://portaleindirettidev.azurewebsites.net/',
  accountNumberOPNet: 'CCF1141'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
