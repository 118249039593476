import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Role } from '@constants/interfaces';
import { IGenericHttpResponse, ILogin, Permission, PermissionName, Profile, UserLogin } from '@modules/login/login.interface';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ILoginResponse, IOtpResponse, SetMfaResponse, UserOtp } from './../login.interface';
import { mergeArraysUniqueByKey, ProfileType } from '@constants/constants';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  public LOGIN_URL: string = '/auth/login';
  public LOGOUT_URL: string = '/auth/logout';
  public PROFILE_URL: string = '/profile';
  public SET_MFA_URL: string = '/auth/mfa-method';
  public GET_TRANSACTION_ID_URL: string = '/auth/refresh-otp';
  public OTP_URL: string = '/auth/validate-code';
  public RESET_PASSWORD_URL: string = '/administration/reset-password';

  constructor(private http: HttpClient) {}

  login(payload: UserLogin): Observable<Partial<ILogin>> {
    return this.http.post<IGenericHttpResponse>(`${environment.PA_PUBLIC_BASE_URL + this.LOGIN_URL}`, payload).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = response.status.descrizione;
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
          // todo redirect to oops page with error message
        }
      }),
      map(res => res.data as ILoginResponse),
      map(res => ({
        mfa: !res.need_configure_mfa,
        idToken: res.id_token,
        username: res.username,
        expirationDate: res.expiration_date,
        firstAccess: res.firstAccess,
        mfaMethod: res.mfa_method,
        transactionId: res.transaction_id,
        name: parseJwt(res.id_token).given_name || 'Agente',
        refreshToken: res.refresh_token,
        lockedUser: {
          locked: res.locked_user.locked,
          lockDuration: res.locked_user.lock_duration,
          unlockHour: res.locked_user.unlock_hour
        }
      }))
    );
  }

  logout(): Observable<boolean> {
    return this.http.post<IGenericHttpResponse>(`${environment.PA_PUBLIC_BASE_URL + this.LOGOUT_URL}`, {}).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = response.status.descrizione;
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
          // todo redirect to oops page with error message
        }
      }),
      map(res => res.data)
    );
  }

  profile(): Observable<Partial<Profile>> {
    return this.http.get<IGenericHttpResponse>(`${environment.PA_PRIVATE_BASE_URL + this.PROFILE_URL}`).pipe(
      tap(response => {
        // Recupera lo sfid dal profilo
        const agencyAgencyProfile = response?.data?.agency;
        const authGroupProfile = response?.data?.authorizationGroup;
        const operatorIdProfile = response?.data?.operatorId;
        const profileType = response?.data?.profile.type;

        if (response.status.codice !== '000') {
          const errorMessage = 'Si è verificato il seguente errore in fase di ottenimento dei dati del profilo: ' + response.status.descrizione;
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
          // todo redirect to oops page with error message
        }
        if ((!agencyAgencyProfile && profileType === ProfileType.EXTERNAL_SALES) || !authGroupProfile || !operatorIdProfile) {
          throw { code: 998 };
        }
      }),
      map(res => res.data as Profile),
      map(res => {
        return {
          agency: { sfid: res.agency.toString() },
          email: res.email,
          operatorId: res.operatorId,
          authorizationGroup: res.authorizationGroup,
          name: res.name,
          tipology: res.tipology,
          surname: res.surname,
          fiscalCode: res.fiscalCode,
          phone: res.phone,
          username: res.username,
          password: res.password,
          document: res.document,
          sportelli: res.sportelli,
          channel: res.channel,
          permissions: mergeArraysUniqueByKey(
            res.permissions,
            res.profile.permissions?.filter(perm => perm.defaultPermission).map(perm => perm.permission),
            'id'
          ) as PermissionName[],
          profile: {
            id: res.profile.id,
            name: res.profile.name,
            type: res.profile.type,
            permissions: res.profile.permissions.filter(el => el.defaultPermission).map(el => ({ id: el.id, permission: el.permission } as Permission))
          } as Role,
          boeNew: res.boeNew || true
        };
      })
    );
  }

  setMfa(payload: string, refreshToken: string): Observable<SetMfaResponse> {
    return this.http.post<IGenericHttpResponse>(`${environment.PA_PRIVATE_BASE_URL + this.SET_MFA_URL}`, {}, { params: { 'mfa-method': payload, 'refresh-token': refreshToken } }).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'Si è verificato il seguente errore in fase di login: ' + response.status.descrizione;
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
          // todo redirect to oops page with error message
        }
      }),
      map(res => res.data),
      map(res => ({
        qrCode: res.qrCode || 'EMAIL',
        transactionId: res.transactionId,
        token: {
          mfa: !res.token.need_configure_mfa,
          idToken: res.token.id_token,
          username: res.token.username,
          expirationDate: res.token.expiration_date,
          firstAccess: res.token.firstAccess,
          mfaMethod: res.token.mfa_method,
          transactionId: res.token.transaction_id,
          name: parseJwt(res.token.id_token).given_name || 'Agente',
          refreshToken: res.token.refresh_token,
          lockedUser: {
            locked: res.token.locked_user.locked,
            lockDuration: res.token.locked_user.lock_duration,
            unlockHour: res.token.locked_user.unlock_hour
          }
        } as ILogin
      }))
    );
  }

  otp(payload: UserOtp): Observable<IOtpResponse> {
    return this.http.get<IGenericHttpResponse>(`${environment.PA_PRIVATE_BASE_URL + this.OTP_URL}`, { params: { code: payload.otp, transactionId: payload.transactionId || '' } }).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'Si è verificato il seguente errore in fase di login: ' + response.status.descrizione;
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
          // todo redirect to oops page with error message
        }
      }),
      map(res => ({
        otp: !!res.data
      }))
    );
  }

  resetPassword(username: string) {
    return this.http.post<IGenericHttpResponse>(`${environment.PA_PUBLIC_BASE_URL + this.RESET_PASSWORD_URL}`, {}, { params: { username: username } }).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'Si è verificato il seguente errore in fase di login: ' + response.status.descrizione;
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
          // todo redirect to oops page with error message
        }
      }),
      map(res => res.status.codice === '000')
    );
  }

  getTransactionId() {
    return this.http.get<IGenericHttpResponse>(`${environment.PA_PRIVATE_BASE_URL + this.GET_TRANSACTION_ID_URL}`).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'Si è verificato il seguente errore in fase di login: ' + response.status.descrizione;
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
          // todo redirect to oops page with error message
        }
      }),
      map(res => res.data as string)
    );
  }
}

export function parseJwt(token: string) {
  const base64Url = token?.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}
