import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalCtaTypes, ModalStatus, ModalTitles, openDialog } from '@constants/constants';
import { PasswordEditService } from '@core/services/password-edit.service';
import { AuthGetOtpAction, AuthLogoutAction } from '@core/store/user/user.actions';
import { AuthSelector } from '@core/store/user/user.selectors';
import { ILogin } from '@modules/login/login.interface';
import { Store } from '@ngrx/store';
import { map, Observable, Subscription, take, tap, timer } from 'rxjs';

@Component({
  selector: 'iren-otp-input-page',
  templateUrl: './otp-input-page.component.html',
  styleUrls: ['./otp-input-page.component.scss']
})
export class OtpInputPageComponent implements OnInit {
  public otpForm = new FormGroup({
    otp: new FormControl('', [Validators.required, Validators.minLength(6)])
  });
  private auth: Partial<ILogin>;
  private secondOtpFirstAccess: boolean = false;
  public noReset: boolean = true;
  public loading: boolean = false;
  public data: { firstAccess: boolean; google_qr_code: string; mfa: boolean } = { firstAccess: false, google_qr_code: '', mfa: false };
  public timer$: Observable<number>;
  public expiredTime: number = 120;
  public subs: Subscription = new Subscription();
  @ViewChild('ngOtpInput') ngOtpInputRef: any;
  constructor(private dialog: MatDialog, private passwordEditService: PasswordEditService, private router: Router, private route: ActivatedRoute, private store: Store, private ref: ChangeDetectorRef) {
    this.noReset = this.route.snapshot.paramMap.get('reset') !== 'reset';
  }

  ngOnInit(): void {
    this.store
      .select(AuthSelector)
      .pipe(
        take(1),
        tap(res => {
          this.auth = res!;
          this.data.firstAccess = res?.firstAccess!;
          this.data.mfa = res?.mfa!;
          this.data.google_qr_code = res?.googleQrCode!;
          this.secondOtpFirstAccess = !!this.data.google_qr_code;
        })
      )
      .subscribe();

    this.timer$ = timer(0, 1000).pipe(
      map(i => {
        return this.expiredTime - i;
      }),
      take(this.expiredTime + 1)
    );
    this.subs.add(
      this.timer$.subscribe(el => {
        if (el <= 0) {
          if (this.ngOtpInputRef) this.ngOtpInputRef.otpForm?.disable();
          this.ref.detectChanges();
        }
      })
    );
  }

  sendOtp() {
    if (this.controlOtp.value.length >= 6 && this.controlOtp.valid) {
      this.loading = true;
      if (this.secondOtpFirstAccess) {
        this.store.dispatch(AuthGetOtpAction({ otp: this.controlOtp.value.trim(), firstAccess: this.data.firstAccess, auth: this.auth!, mfa: this.data.mfa }));
      } else if (this.auth.firstAccess) {
        this.passwordEditService.validateFirstAccessCode(this.controlOtp.value.trim(), this.auth.transactionId).subscribe({
          next: res => {
            if (res) {
              this.router.navigate(['/choice-mfa']);
            } else {
              openDialog({
                dialog: this.dialog,
                status: ModalStatus.ERROR,
                ctaType: ModalCtaTypes.CLOSE,
                title: ModalTitles.LOGIN,
                message: 'OTP ERRATO'
              });
            }
          },
          error: err => {
            openDialog({
              dialog: this.dialog,
              errorCode: err.code,
              status: ModalStatus.ERROR,
              ctaType: ModalCtaTypes.CLOSE,
              title: ModalTitles.LOGIN,
              message: err.error?.message
            });
          }
        });
      } else {
        this.router.navigate(['/edit-password-reset', this.controlOtp.value.trim()]);
      }
    }
  }

  public goToLogin() {
    this.store.dispatch(AuthLogoutAction({ goToLogin: true, number: '25' }));
  }

  public get controlOtp() {
    return this.otpForm.get('otp') as FormControl;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
