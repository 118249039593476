import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthSetProfileAction } from '@core/store/user/user.actions';
import { Store } from '@ngrx/store';

@Component({
  selector: 'iren-first-auth-confirm-page',
  templateUrl: './first-auth-confirm-page.component.html',
  styleUrls: ['./first-auth-confirm-page.component.scss']
})
export class FirstAuthConfirmPageComponent {
  public firstAccess: boolean;

  constructor(private router: Router, private route: ActivatedRoute, private store: Store) {
    this.firstAccess = this.route.snapshot.paramMap.get('firstAccess') === 'first';
  }

  public goToLogin() {
    this.router.navigate(['/login']);
  }

  public goToHomepage() {
    this.store.dispatch(AuthSetProfileAction());
  }
}
