import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IrenPaSpinnerComponent } from './iren-pa-spinner/iren-pa-spinner.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [IrenPaSpinnerComponent],
  imports: [CommonModule, MatProgressSpinnerModule],
  exports: [IrenPaSpinnerComponent]
})
export class SpinnerModule {}
