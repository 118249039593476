import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { MandatoryTrainingFlagSelector } from '@core/store/store.selector';
import { Store } from '@ngrx/store';
import { mergeMap, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TrainingRequiredGuard implements CanActivate {
  constructor(private store: Store, private router: Router) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.store.select(MandatoryTrainingFlagSelector).pipe(
      mergeMap(el => {
        if (!el) {
          return of(true);
        } else {
          this.router.navigate(['training-required']);
          return of(false);
        }
      })
    );
  }
}
