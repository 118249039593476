import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { IStoreState } from '../store/store.state';
import { TokenSelector } from '../store/user/user.selectors';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private store: Store<IStoreState>) {}
  private endpointWithoutToken: string[] = ['ingest.sentry.io', 'auth/login', 'api.cloudinary.com', 'verify/captcha', 'reset-password', 'public/auth/change-password'];

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store.pipe(
      select(TokenSelector),
      take(1),
      map(token => {
        if (!this.endpointWithoutToken.some(endpoint => request.url.includes(endpoint))) {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`
            }
          });
        }
        return request;
      }),
      mergeMap(req => next.handle(req))
    );
  }
}
