import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { getErrorMessage, ModalStatus } from '@constants/constants';
import { ModalCtaTypes } from '@constants/constants';
import { Modal } from '@constants/interfaces';

@Component({
  selector: 'iren-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalComponent implements OnInit {
  public ModalCtaTypes = ModalCtaTypes;
  public modalStatus = ModalStatus;
  constructor(@Inject(MAT_DIALOG_DATA) public data: Partial<Modal>, private router: Router) {}

  ngOnInit(): void {
    if (this.data.status === this.modalStatus.ERROR && !this.data.message) {
      this.data.message = getErrorMessage(this.data.errorCode!);
    }
  }
  public returnHp() {
    if (this.data.funnelCommodity) {
      this.router.navigate(['/my-area', 'homepage']);
    }
  }

  getTitleStyles() {
    if (this.data.titleStyle) {
      return this.data.titleStyle;
    }
    return null;
  }

  getMessageStyles() {
    if (this.data.messageStyle) {
      return this.data.messageStyle;
    }
    return null;
  }

  getMessagePointBreak(message: string, separator: string) {
    return message.split(separator);
  }
}
