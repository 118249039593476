import { createAction, props } from '@ngrx/store';
import * as State from './training.state';
import { Pagination, Role } from '@constants/interfaces';

export const TrainingClearActionType = '[Training] Clearing';
export const TrainingClearAction = createAction('[Training] Clearing');
export const TrainingGetListAction = createAction('[Training] Get List', props<{ filter: any }>());
export const TrainingLoadedListAction = createAction('[Training] Add total count list', props<{ trainingList: Partial<State.Training>[]; totalCount: number }>());
export const TrainingCreateCourseStep1 = createAction('[Training] create course step 1', props<{ newCourse: Partial<State.Training> }>());
export const TrainingDeleteCourse = createAction('[Training] Delete course', props<{ uuid: string }>());
export const TrainingDeletedCourse = createAction('[Training] deleted course', props<{ uuid: string }>());
export const TrainingSendNewCourse = createAction('[Training] send new course', props<{ newCourse: Partial<State.Training> }>());
export const TrainingSendingNewCourse = createAction('[Training] sending new course', props<{ newCourse: Partial<State.Training> }>());
export const TrainingEditCourse = createAction('[Training] edit new course', props<{ newCourse: Partial<State.Training> }>());
export const TrainingEditingCourse = createAction('[Training] editing new course', props<{ newCourse: Partial<State.Training> }>());
export const SetRoleTrainingList = createAction('[Training] set roles', props<{ profileList: Partial<Role>[] }>());
export const SetTrainingPaginationAction = createAction('[Training] Set Pagination ', props<{ pagination: Partial<Pagination> }>());
