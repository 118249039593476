import { Component } from '@angular/core';

@Component({
  selector: 'iren-kickout-modal',
  templateUrl: './kickout-modal.component.html',
  styleUrls: ['./kickout-modal.component.scss']
})
export class KickOutModalComponent {
  constructor() {}
}
