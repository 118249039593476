import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AggregatedTracking, IGenericHttpResponse } from '@modules/login/login.interface';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Comune } from '@core/store/store.state';

@Injectable({
  providedIn: 'root'
})
export class HomepageService {
  constructor(private http: HttpClient) {}
  private readonly GET_AGGREGATED_TRACKING = 'tracking-sales/aggregated';
  private readonly GET_CODICE_CATASTALE_COMUNE = 'search/comune';

  getDailyTask(): Observable<any> {
    return this.http.get<IGenericHttpResponse>(`${environment.PA_PRIVATE_BASE_URL}/getDailyTask`).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'HOMEPAGE- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
          // todo redirect to oops page with error message
        }
      }),
      map(res => ({
        tasks: res
      }))
    );
  }

  getAggregatedTracking(): Observable<Partial<AggregatedTracking>> {
    return this.http.get<IGenericHttpResponse>(`${environment.PA_PRIVATE_BASE_URL}/${this.GET_AGGREGATED_TRACKING}`).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'HOMEPAGE- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
          // todo redirect to oops page with error message
        }
      }),
      map(res => res.data as AggregatedTracking),
      map(res => ({
        commodityClosedLost: res.commodityClosedLost,
        commodityClosedWon: res.commodityClosedWon,
        draft: res.draft,
        vasKo: res.vasKo
      }))
    );
  }

  getCodiceCatastaleComuni(): Observable<Comune[]> {
    return this.http.get<any>(`${environment.PA_PRIVATE_BASE_URL}/${this.GET_CODICE_CATASTALE_COMUNE}`).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'HOMEPAGE- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
          // todo redirect to oops page with error message
        }
      }),
      map(res => {
        const comuni = res.lista.map((el: any) => {
          return {
            value: el.codCatastale,
            viewValue: `${el.nome} (${el.siglaProvincia})`,
            cessato: el.cessato
          };
        });
        return comuni;
      })
    );
  }

  getTrainingList(): Observable<any> {
    return this.http.get<IGenericHttpResponse>(`${environment.PA_PRIVATE_BASE_URL}/getTraining`).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'HOMEPAGE- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
          // todo redirect to oops page with error message
        }
      }),
      map(res => ({
        tasks: res
      }))
    );
  }
}
