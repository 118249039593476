<div class="otp">
  <mat-icon class="otp__close" color="accent" mat-dialog-close>close</mat-icon>
  <h2>Ciao {{ auth.name }}</h2>
  <mat-icon>phone_android</mat-icon>
  <div *ngIf="data?.google_qr_code?.length || auth.mfaMethod === 'APP'; else email">
    <h2 *ngIf="data?.google_qr_code?.length">Scansione il QR code per verificare il tuo account</h2>
    <qr-code [value]="data.google_qr_code" size="256" errorCorrectionLevel="M"></qr-code>
    <form class="otp__form" [formGroup]="otpForm">
      <h2>Inserisci il codice ricevuto sul tuo dispositivo</h2>
      <div class="otp-input-container">
        <ng-otp-input #ngOtpInput [formCtrl]="controlOtp" [config]="conf"></ng-otp-input>
      </div>
    </form>
    <div class="mt-1">
      <button mat-raised-button color="accent" (click)="sendOtp()">Invia OTP</button>
    </div>
  </div>
  <ng-template #email>
    <h2>È stato inviato il codice OTP alla mail utilizzata in fase di registrazione</h2>
    <form class="otp__form" [formGroup]="otpForm">
      <h2>Inserisci il codice OTP{{ (timer$ | async) ? ', hai\ ' + (timer$ | async) + '\ secondi' : '' }}</h2>
      <div class="otp-input-container">
        <ng-otp-input #ngOtpInput [formCtrl]="controlOtp" [config]="conf"></ng-otp-input>
      </div>
    </form>
    <h3 style="color: red">{{ !(timer$ | async) ? "Il tempo per l' inserimento dell' OTP è scaduto, devi effettuare di nuovo l' accesso" : '' }}</h3>
    <button mat-raised-button color="accent" class="mt-1" *ngIf="timer$ | async; else exit" (click)="sendOtp()">Invia OTP</button>
    <ng-template #exit>
      <button mat-raised-button color="accent" class="mt-1" mat-dialog-close>Torna alla login</button>
    </ng-template>
  </ng-template>
</div>
