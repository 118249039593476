import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthSelector } from '@core/store/user/user.selectors';
import { Store } from '@ngrx/store';
import { map, Observable, take, tap, timer } from 'rxjs';
import { ILogin } from '@modules/login/login.interface';
import { NgOtpInputConfig } from 'ng-otp-input';

@Component({
  selector: 'iren-otp-input-modal',
  templateUrl: './otp-input-modal.component.html',
  styleUrls: ['./otp-input-modal.component.scss']
})
export class OtpInputModalComponent implements OnInit {
  public otpForm = new FormGroup({
    otp: new FormControl('', [Validators.required, Validators.minLength(6)])
  });
  public auth: Partial<ILogin>;
  public timer$: Observable<number>;
  public expiredTime: number = 120;
  public config: NgOtpInputConfig = { length: 6, allowNumbersOnly: true, disableAutoFocus: true };

  @ViewChild('ngOtpInput') ngOtpInputRef: any;
  conf: NgOtpInputConfig = {
    length: 6,
    allowNumbersOnly: true,
    disableAutoFocus: true
  };

  constructor(private store: Store, public ref: ChangeDetectorRef, public dialogRef: MatDialogRef<OtpInputModalComponent>, @Inject(MAT_DIALOG_DATA) public data: { firstAccess: boolean; google_qr_code: string; mfa: boolean }) {
    this.store
      .select(AuthSelector)
      .pipe(
        take(1),
        tap(res => {
          this.auth = res!;
          this.data.google_qr_code = res?.googleQrCode!;
        })
      )
      .subscribe();
  }

  ngOnInit(): void {
    if (this.auth.mfaMethod !== 'APP') {
      this.timer$ = timer(0, 1000).pipe(
        map(i => {
          return this.expiredTime - i;
        }),
        take(this.expiredTime + 1)
      );
      this.timer$.subscribe(el => {
        if (el <= 0) {
          this.ngOtpInputRef.otpForm.disable();
          this.ref.detectChanges();
        }
      });
    }
  }

  sendOtp() {
    this.dialogRef.close({ valid: true, val: this.controlOtp.value.trim() });
  }

  public get controlOtp() {
    return this.otpForm.get('otp') as FormControl;
  }
}
