import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

@Injectable({
  providedIn: 'root'
})
export class CustomDateAdapter extends NativeDateAdapter {
  override parse(value: any): Date | null {
    if (typeof value === 'string') {
      value = value.replace(/(\.)|(\-)|(\\)/g, '/');
      if (value.indexOf('/') > -1) {
        const str = value?.split('/');

        const year = Number(str[2]);
        const month = Number(str[1]) - 1;
        const date = Number(str[0]);

        return new Date(year, month, date);
      }
    }

    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }

  override getFirstDayOfWeek(): number {
    return 1;
  }
  override addCalendarYears(date: Date, years: number): Date {
    const newDate = super.addCalendarYears(date, years);
    return newDate < new Date(1900, 0, 1) ? new Date(1900, 0, 1) : newDate;
  }
 
  override clampDate(date: Date): Date {
    const minDate = new Date(1900, 0, 1);
    if (date < minDate) {
      return minDate;
    }
    return date;
  }
}
