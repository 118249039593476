import { createReducer } from '@ngrx/store';
import { paStoreKey } from './store.selector';
import { EmptyStoreState, InitialStoreState } from './store.state';
import { AuthLogoutAction } from './user/user.actions';
import { userReducer } from './user/user.reducers';
import { ResetPaStoreKey } from './store.action';

export const rootReducers = createReducer(InitialStoreState, ...userReducer);

export function getStoreReducers() {
  return {
    [paStoreKey]: rootReducers
  };
}

export function persistStoreReducer(reducer: any) {
  return function (state: any, action: any) {
    if (!state && localStorage.getItem(paStoreKey)) state = { paStoreKey: JSON.parse(localStorage.getItem(paStoreKey)!) };
    else if (state && action.type != AuthLogoutAction.type && action.type != ResetPaStoreKey.type) {
      Object.keys(state)
        .filter(key => [paStoreKey].includes(key))
        .forEach(key => {
          const currentTypes = userReducer.map(r => r.types[0]);

          if (currentTypes.includes(action.type)) {
            const newLocalStorage = Object.assign({}, state[key], { ...action });
            delete newLocalStorage.type;
            localStorage.setItem(key, JSON.stringify(newLocalStorage));
          }
        });
    } else {
      localStorage.removeItem(paStoreKey);
      //state = { paStoreKey: EmptyStoreState };
    }

    return reducer(state, action);
  };
}
