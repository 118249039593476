import { Injectable } from '@angular/core';
import { ContractRequest, ContractResponse, PostSalesService } from '@modules/post-sales/services/post-sales.service';
import { Observable } from 'rxjs';
import * as State from '../store/sales.state';
import { AddressFullRequest, CodiceScontoVasRequest, CodiceScontoVasResponse, GetConsumoRequest, OutGoingSeller, RcaInfoByPlate, SalesApiService, SportelliResponse, UploadDocumentRequest, UploadPrecompiledPlicoRequest } from './sales-api.service';
import { CheckBillingProfileRequest, CheckBillingProfileResponse, CheckPodPdrResponse, GetPdrResponse, GetPodResponse, Plico } from './sales-common.interfaces';
import { ListaMotivazioniIvaAgevolata, MotivazioniIvaAgevolata } from '../store/sales.state';
import { IGenericHttpResponse } from '@modules/login/login.interface';

@Injectable({
  providedIn: 'root'
})
export class SalesService {
  private isExitFromFunnel: boolean = false;

  constructor(private apiService: SalesApiService, private postSalesService: PostSalesService) {}

  get IsExitFromFunnel() {
    return this.isExitFromFunnel;
  }

  set IsExitFromFunnel(bool: boolean) {
    this.isExitFromFunnel = bool;
  }

  getPod(body: any): Observable<Partial<GetPodResponse>> {
    return this.apiService.getPod(body);
  }
  getPdr(body: any): Observable<Partial<GetPdrResponse>> {
    return this.apiService.getPdr(body);
  }
  checkPodPdr(body: any): Observable<Partial<CheckPodPdrResponse>> {
    return this.apiService.checkPodPdr(body);
  }
  getSalesInitialCheck(action: Partial<State.SalesInitialCheck>, newCustomer: boolean): Observable<State.SalesEsito> {
    return this.apiService.getSalesInitialCheck(action, newCustomer);
  }
  getSalesVasInitialCheck(action: any): Observable<State.SalesEsito> {
    return this.apiService.getSalesVasInitialCheck(action);
  }
  getMotivazioniIvaAgevolata(): Observable<any> {
    return this.apiService.getSalesMotivazioniIvaAgevolata();
  }
  saveDraftPractice(body: any): Observable<any> {
    return this.apiService.saveDraftPractice(body);
  }
  saveFinalSalesState(body: any, type: string): Observable<any> {
    return this.apiService.saveFinalSalesState(body, type);
  }
  checkMarketability(body: any): Observable<any> {
    return this.apiService.checkMarketability(body);
  }
  getVenditoriUscenti(): Observable<OutGoingSeller[]> {
    return this.apiService.getVenditoriUscenti();
  }
  getSportelli(userCompany?: string): Observable<Partial<SportelliResponse>[]> {
    return this.apiService.getSportelli(userCompany);
  }
  getRandomCode(): Observable<string> {
    return this.apiService.getRandomCode();
  }
  getFea(body: any, noFilter: boolean, noError: boolean): Observable<any> {
    return this.postSalesService.getRegistry(body, noFilter, noError);
  }
  getContract(body: Partial<ContractRequest>, noActive?: boolean): Observable<ContractResponse> {
    return this.postSalesService.getContract(body, noActive);
  }
  checkBillingProfile(body: Partial<CheckBillingProfileRequest>): Observable<Partial<CheckBillingProfileResponse>> {
    return this.apiService.checkBillingProfile(body);
  }
  uploadPrecompiledPlico(body: UploadPrecompiledPlicoRequest, vas?: string): Observable<any> {
    return this.apiService.uploadPrecompiledPlico(body, vas);
  }
  uploadVerbalOrder(body: UploadPrecompiledPlicoRequest): Observable<any> {
    return this.apiService.uploadVerbalOrder(body);
  }
  getMp3Contr(randomCode: string): Observable<string> {
    return this.apiService.getMp3Contr(randomCode);
  }
  uploadMp3Contr(body: UploadPrecompiledPlicoRequest): Observable<string> {
    return this.apiService.uploadMp3Contr(body);
  }
  archiveDocument(body: UploadDocumentRequest): Observable<any> {
    return this.apiService.archiveDocument(body);
  }
  checkCfWhitelist(cf: string) {
    return this.apiService.checkCfWhitelist(cf);
  }
  getAddressOpNet(address: string) {
    return this.apiService.getAddressOpNet(address);
  }
  getAddressFullOpNet(addressFull: Partial<AddressFullRequest>) {
    return this.apiService.getAddressFullOpNet(addressFull);
  }
  validateMigrazionCodeOpNet(migCode: string) {
    return this.apiService.validateMigrazionCodeOpNet(migCode);
  }
  verificaCoperturaOpNet(body: any, coperturaSelezionata: string) {
    return this.apiService.verificaCoperturaOpNet(body, coperturaSelezionata);
  }
  salvaPreoffertaOpNet(body: any, prodotto: any) {
    return this.apiService.salvaPreoffertaOpNet(body, prodotto);
  }
  getPlico(randomCode: string): Observable<Partial<Plico>> {
    return this.apiService.getPlico(randomCode);
  }
  getLeadCheckPresence(filter: any) {
    return this.apiService.getLeadCheckPresence(filter);
  }
  getWorkOrder(supplyPoint: string): Observable<any> {
    return this.apiService.getWorkOrder(supplyPoint);
  }
  resendSignature(body: any) {
    return this.apiService.resendSignature(body);
  }
  checkRandomCode(randomCode: string) {
    return this.apiService.checkRandomCode(randomCode);
  }
  uploadDocument(body: any): Observable<string> {
    return this.apiService.uploadDocument(body);
  }
  getRcaInfoByPlate(body: any): Observable<RcaInfoByPlate> {
    return this.apiService.getRcaInfoByPlate(body);
  }
  getCheckCodiceScontoVas(body: Partial<CodiceScontoVasRequest>): Observable<Partial<CodiceScontoVasResponse>[]> {
    return this.apiService.getCheckCodiceScontoVas(body);
  }
  ageCheck(cf: string) {
    return this.apiService.ageCheck(cf);
  }
  getConsumoSAP(body: GetConsumoRequest) {
    return this.apiService.getConsumoSAP(body);
  }
}
