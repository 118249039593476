import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { GuardSelector, TokenSelector } from './../store/user/user.selectors';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private store: Store, private router: Router) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.store.select(GuardSelector).pipe(
      mergeMap(el => {
        if (el) {
          return this.store.select(TokenSelector).pipe(
            mergeMap(token => {
              if (token && this.isTokenValid(token)) {
                console.log('GUARD PASS');
                return of(true);
              } else {
                console.log('GUARD NOT PASS - Invalid or expired token');
                this.router.navigate(['login']);
                return of(false);
              }
            })
          );
        } else {
          console.log('GUARD NOT PASS - Missing necessary data');
          this.router.navigate(['login']);
          return of(false);
        }
      })
    );
  }

  private isTokenValid(token: string): boolean {
    try {
      const payload = JSON.parse(atob(token.split('.')[1]));
      const exp = payload.exp;
      const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
      return exp > currentTime;
    } catch (e) {
      console.error('Failed to decode token', e);
      return false;
    }
  }
}
