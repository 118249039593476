import { createSelector } from '@ngrx/store';
import { storeSelector } from '../store.selector';

export const currentUser = createSelector(storeSelector, store => store?.profile);
export const AuthSelector = createSelector(storeSelector, store => store?.auth);
export const TokenSelector = createSelector(storeSelector, store => store?.auth?.idToken);
export const GuardSelector = createSelector(storeSelector, store => store?.auth?.idToken && store?.profile?.operatorId);
export const AggregatedTrackingSelector = createSelector(storeSelector, store => store?.aggregatedTracking);
export const NotificationListSelector = createSelector(storeSelector, state => state.notifications?.filter(el => !el.visualized && el.notification.type !== 'DAILY_TASK'));
export const DailyTaskSelector = createSelector(storeSelector, state => state.dailyTask.find(el => !el.visualized && el.notification.type === 'DAILY_TASK'));
