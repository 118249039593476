import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from 'src/app/app.module';
import { environment } from 'src/environments/environment';
import * as Sentry from '@sentry/angular';

Sentry.init({
  dsn: environment.sentryDsn,
  debug: false,
  environment: environment.name,
  // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring. We recommend adjusting this value in production
  tracesSampleRate: 0.5, // todo change to 0.1 at 01/10/2023
  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    Sentry.browserTracingIntegration({}),
    // Add browser profiling integration to the list of integrations
    Sentry.browserProfilingIntegration(),
    // Registers the Replay integration, which automatically captures Session Replays
    Sentry.replayIntegration({
      // Additional SDK configuration goes in here, for example:
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: true
    })
  ],
  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: [/^https:\/\/www.irenforce.gruppoiren\.it\/api/],
  // Capture Replay for 10% of all sessions, plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  /*integrations: [
    new BrowserTracingIntegration({
      tracingOrigins: [environment.PA_BASE_URL],
      routingInstrumentation: Sentry.routingInstrumentation
    }),
    new Sentry.Replay({
      //networkDetailAllowUrls: ['*!/api/proxy/private/v2/precheck/full'],
      networkRequestHeaders: ['application/json, text/plain, *!/!*'],
      networkResponseHeaders: ['Content-Type,Content-Length,Authorization,Accept,X-Requested-With, sentry-trace, baggage'],
      maskAllText: false,
      blockAllMedia: true
    })
  ]*/

  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // result in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 1.0
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(success => console.log(`Angular Bootstrap success: `, success))
  .catch(err => console.error('Angular Bootstrap Error :', err));
